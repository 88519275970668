import { Avatar, Form, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AxiosResponse } from 'axios';
import DollarInput from 'components/DollarAmount';
import ModalDark from 'components/ModalDark';
import Text from 'components/Text';
import { first, get, isEmpty, set } from 'lodash';
import moment from 'moment';
import React, { CSSProperties, forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { BillPreviewLayout, useBillPreviewLayout } from 'Receipts';
import commActions from 'services/actions/comm';
import apis from 'services/apis';
import commSelectors from 'services/selectors/comm';
import shopSelectors from 'services/selectors/shop';
import { IApiAddCommissionBody, IApiUpdateCommissionBody, IQuickCommissionItem, IQuickCommissionPaymentItem } from 'services/types/comm';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
import { formatCurrency } from 'utils/formatCurrency';
import { useModalConfirmContext } from 'widgets/ModalConfirm';
import { verifyPinCode } from 'widgets/ModalVerifyAdminPin';
import AddNewQuickCommission, { useAddNewQuickCommissionRef } from './create';
import { payments, QuickCommissionPaymentMethods } from './create/TicketTableContent';
import { emptyLocaleTable } from './EmptyTable';
import accept_icon from './icons/accept.svg';
import add_icon from './icons/add.svg';
import cancel_icon from './icons/cancel.svg';
import cash_icon from './icons/cash.svg';
import change_icon from './icons/change.svg';
import copy_icon from './icons/copy.svg';
import credit_card_icon from './icons/credit-card.svg';
import delete_icon from './icons/delete.svg';
import edit_icon from './icons/edit.svg';
import gift_card_icon from './icons/gift-card.svg';
import print_icon from './icons/print.svg';
import RangePicker from './RangePicker';
import StaffPicker from './StaffPicker';
import SummaryTotal from './SummaryTotal';
import toast from 'utils/toast';

export interface IFormUpdateTicketValues {
  ticketId: number;
  subTotal: number;
  tip: number;
  discount: number;
  supplyFee: number;
  total: number;
  paymentMethod: number;
}
type Props = {};
type Ref = {
  open: () => void;
};

const containerStyles: CSSProperties = {
  gap: 0
};
const headerStyles: CSSProperties = {
  position: 'sticky',
  top: '0px',
  zIndex: 99,
};
const bodyStyles: CSSProperties = {
  minHeight: '600px',
  padding: '16px',
};
const footerStyles: CSSProperties = {
  position: 'sticky',
  bottom: '0px',
  zIndex: 99,
};
const summary = () => <Table.Summary fixed><SummaryTotal /></Table.Summary>;
export const useQuickCommissionRef = () => useRef<Ref>(null);
export const globalQuickCommissionRef = React.createRef<Ref>();
const QuickCommission = forwardRef<Ref, Props>(({ }, ref) => {
  const dispatch = useAppDispatch();
  const addNew = useAddNewQuickCommissionRef();
  const [open, setOpen] = useState(false);
  const staffPrintRef = useRef<HTMLDivElement>(null);
  const billStaffCtr = useBillPreviewLayout();
  const doStaffPrint = useReactToPrint({ contentRef: staffPrintRef });

  const billOwnerCtr = useBillPreviewLayout();
  const ownerPrintRef = useRef<HTMLDivElement>(null);
  const doOwnerPrint = useReactToPrint({ contentRef: ownerPrintRef });
  const state = commSelectors.state();
  const [editId, setEditId] = useState<number | null>(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [confirmRef, confirmContext] = useModalConfirmContext();
  const [activeStaff, setActiveStaff] = useState<{ staffId: string, position: string, staffName: string; } | null>(null);
  const staffs = shopSelectors.staff();

  const staffInfo = useMemo(() => {
    const objectStaffInfo: Record<string, IStaffUI> = {};
    let i = 1;
    staffs.forEach((item) => {
      const result: IStaffUI = ({
        ...item,
        typeColor: i,
      });
      i++;
      if (i > 6) {
        i = 1;
      }
      set(objectStaffInfo, item.id, result);
    });
    return objectStaffInfo;
  }, [staffs]);

  const dataSource: IQuickCommissionItem[] = useMemo(() => {
    if (!state?.commissions) return [];
    return state.commissions.map(o => {
      return ({
        ...o,
        staffInfo: get(staffInfo, o.staff.staffId, null),
      } as IQuickCommissionItem);
    });
  }, [state.commissions, staffs, staffInfo]);

  const printReportOwner = async () => {
    const fromDate = moment(state.params.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
    const toDate = moment(state.params.endDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: any }> = await apis.getOwnerReportCommissions(fromDate, toDate);
      if (!isEmpty(res.data.payload)) {
        dispatch(commActions.getOwnerReportCommissions.success(res.data.payload));
        setTimeout(() => billOwnerCtr.current?.open(), 100);
      } else {
        throw 'fail';
      }
    } catch (error) {
      const msg = get(error, 'response.data.message');
      toast.error(decodeURI(msg ?? 'System error'));
    }
    finally {
      setLoading(false);
    }
  };

  const printReportStaff = async () => {
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: any }> = await apis.getStaffReportCommissions(state.params.startDate || '', activeStaff?.staffId || '');
      if (!isEmpty(res.data.payload)) {
        dispatch(commActions.getStaffReportCommissions.success(res.data.payload));
        setTimeout(() => billStaffCtr.current?.open(), 100);
      } else {
        throw 'fail';
      }
    } catch (error) {
      const msg = get(error, 'response.data.message');
      toast.error(decodeURI(msg ?? 'System error'));
    }
    finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setEditId(null);
    setOpen(false);
    setActiveStaff(null);
    form.resetFields();
  };

  const RightActionComponent = useCallback(() => <RightActionStyled>
    <StaffPicker activeStaff={activeStaff} />
    <RangePicker activeStaff={activeStaff} />
  </RightActionStyled>, [activeStaff]);

  const Footer = () => {
    return <>
      <button onClick={onClose} type='button' className="btn"><span>Close</span></button>
      <button onClick={() => dispatch(commActions.setParams({}))} type='button' className="btn"><img src={change_icon} alt="change_icon" className='icon' /><span>Refresh</span></button>
      {activeStaff?.position === 'Technician' ?
        <button disabled={!activeStaff} onClick={printReportStaff} type='button' className="btn"><img src={print_icon} alt="print_icon" className='icon' /><span>Print</span></button>
        :
        <button disabled={!activeStaff} onClick={printReportOwner} type='button' className="btn"><img src={print_icon} alt="print_icon" className='icon' /><span>Print</span></button>
      }
      <button onClick={() => addNew.current?.open()} type='button' className="btn btn-submit"><img src={add_icon} alt="add_icon" className='icon' /><span>add commission</span></button>
    </>;
  };



  const handleUpdateItem = async (values: any) => {
    const data = values as IFormUpdateTicketValues;
    const body: IApiUpdateCommissionBody = {
      discount: data.discount,
      paymentType: data.paymentMethod,
      sales: data.subTotal,
      supply: data.supplyFee,
      ticketId: data.ticketId,
      tip: data.tip,
      total: data.subTotal + data.tip - data.discount + data.supplyFee,
    };
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: any }> = await apis.updateCommission(body);
      if (res.data.payload) {
        setEditId(null);
        dispatch(commActions.setParams({}));
      }

    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteItem = async (item: IQuickCommissionItem) => {
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: any }> = await apis.deleteCommissionItem(item.billId);
      if (res.data.payload) {
        setEditId(null);
        dispatch(commActions.setParams({}));
      }

    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleCopyTicket = async (item: IQuickCommissionItem) => {
    if (!activeStaff?.staffId) return;
    setLoading(true);
    const body: IApiAddCommissionBody = {
      items: [{
        discount: item.discount,
        paymentType: item.payments?.[0].paymentType || 0,
        sales: item.subTotal,
        supply: item.supplyFee,
        tip: item.tip,
        total: item.total,
      }],
      createDate: item.createdDate,
      staffId: +item.staff.staffId,
      staffAddTicket: +activeStaff?.staffId,
    };
    try {
      const res: AxiosResponse<{ payload: any }> = await apis.addCommissions(body);
      if (res.data.payload) {
        setEditId(null);
        dispatch(commActions.setParams({}));
      }

    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Date',
      dataIndex: 'createdDate',
      width: 150,
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'Staff',
      dataIndex: 'staffInfo',
      key: 'staffNames',
      width: 150,
      fixed: 'left',
      render(data: IStaffUI | null, record: IQuickCommissionItem) {
        if (!data) return record.staffNames?.join(', ');
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Avatar size={32} src={data.avatar || 'Fail'}>{data.name?.[0] || 'A'}</Avatar>
            <Text variant='CONTENT_2'>{data.name || '--'}</Text>
          </div>
        );
      },
    },
    {
      title: 'Ticket',
      dataIndex: 'ticketNumber',
      key: 'ticketNumber',
      width: 90,
      fixed: 'left',
      align: 'center',
      render: (value: string) => <div style={{ display: 'flex', justifyContent: 'center' }}><TicketNumber><span>#{value}</span></TicketNumber></div>,
    },
    {
      title: 'Payment Method',
      dataIndex: 'payments',
      key: 'payment_method',
      width: 200,
      align: 'center',
      render: (val: IQuickCommissionPaymentItem[], record) => {
        if (editId === record.billId) return (
          <PassValueForm
            name={'paymentMethod'}
            render={(val, onChange) => <Select style={{ height: '2rem', width: 150 }} value={val} onChange={onChange} options={payments} />}
          />
        );
        return val.map(o => <PaymentType key={o.paymentType} type={o.paymentType} />);
      },
    },
    {
      title: 'Sub Total',
      dataIndex: 'subTotal',
      key: 'subTotal',
      align: 'center',
      width: 180,
      className: 'number-row',
      render: (val, record) => {
        if (editId === record.billId) return (
          <PassValueForm
            name={'subTotal'}
            render={(val, onChange = () => undefined) => <DollarInput value={val} onChange={onChange} />}
          />
        );
        return formatCurrency(val);
      },
    },
    {
      title: 'Tip',
      dataIndex: 'tip',
      key: 'tip',
      align: 'center',
      width: 180,
      className: 'number-row',
      render: (val, record) => {
        if (editId === record.billId) return (
          <PassValueForm
            name={'tip'}
            render={(val, onChange = () => undefined) => <DollarInput value={val} onChange={onChange} />}
          />
        );
        return formatCurrency(val);
      },
    },
    {
      title: 'Discount',
      key: 'discount',
      dataIndex: 'discount',
      align: 'center',
      width: 180,
      className: 'number-row',
      render: (val, record) => {
        if (editId === record.billId) return (
          <PassValueForm
            name={'discount'}
            render={(val, onChange = () => undefined) => <DollarInput value={val} onChange={onChange} />}
          />
        );
        return formatCurrency(val);
      },
    },
    {
      title: 'Supply',
      dataIndex: 'supplyFee',
      key: 'supplyFee',
      align: 'center',
      width: 180,
      className: 'number-row',
      render: (val, record) => {
        if (editId === record.billId) return (
          <PassValueForm
            name={'supplyFee'}
            render={(val, onChange = () => undefined) => <DollarInput value={val} onChange={onChange} />}
          />
        );
        return formatCurrency(val);
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      width: 180,
      // fixed: 'right',
      className: 'number-row',
      render: (val, record) => {
        if (editId === record.billId) return (
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const val: IFormUpdateTicketValues = getFieldsValue();
              return (
                <div style={{ fontSize: 18, fontWeight: '600', textAlign: 'right' }}>
                  {formatCurrency(val.subTotal + val.tip - val.discount + val.supplyFee)}
                </div>
              );
            }}
          </Form.Item>

        );
        return formatCurrency(val);
      },
    },

    {
      title: 'Action',
      key: 'action',
      width: 160,
      fixed: 'right',
      align: 'center',
      render(item: IQuickCommissionItem) {
        if (item.billId === editId) {
          return <Actions>
            <button onClick={() => setEditId(null)} type='button' className="btn btn-delete btn-cancel"><img src={cancel_icon} alt="cancel_icon" style={{ width: 24, height: 24 }} /></button>
            <button onClick={() => form.submit()} type='button' className="btn btn-edit btn-accept"><img src={accept_icon} alt="accept_icon" style={{ width: 24, height: 24 }} /></button>
          </Actions>;
        }
        return <Actions>
          <button onClick={() => confirmRef.current?.open(() => handleCopyTicket(item), 'Copy', 'Are you want to copy this ticket?')} type='button' className="btn btn-delete"><img src={copy_icon} alt="copy_icon" style={{ width: 24, height: 24 }} /></button>
          <button onClick={() => {
            const ticketID = editId === item.billId ? null : item.billId;
            setEditId(ticketID);
            if (ticketID) {
              form.setFieldsValue({
                subTotal: item.subTotal,
                discount: item.discount,
                supplyFee: item.supplyFee,
                total: item.total,
                tip: item.tip,
                ticketId: item.billId,
                paymentMethod: first(item.payments)?.paymentType,
              });
            }

          }} type='button' className="btn btn-edit"><img src={edit_icon} alt="edit_icon" style={{ width: 24, height: 24 }} /></button>
          <button onClick={() => confirmRef.current?.open(() => handleDeleteItem(item), '', 'Are you sure delete this ticket?')} type='button' className="btn btn-delete"><img src={delete_icon} alt="delete_icon" style={{ width: 24, height: 24 }} /></button>
        </Actions>;
      },
    },
  ];

  useImperativeHandle(ref, () => ({
    open: () => verifyPinCode.current?.getStaffByPinCode((staff) => {
      setOpen(true);
      const date = moment().format('MM-DD-YYYY');
      setActiveStaff({
        staffId: staff.staffId?.toString(),
        position: staff.position,
        staffName: staff.staffName,
      });
      if (staff.position === 'Technician')
        return dispatch(commActions.init({ staffId: staff.staffId?.toString(), startDate: date, endDate: date, page: 1 }));
      dispatch(commActions.init({ startDate: date, endDate: date, page: 1 }));
    }),
  }));

  const handlePageChange = async (page?: number) => {
    dispatch(commActions.setParams({ page: page || 1 }));
  };

  const rowClassName = useCallback(function (record: IQuickCommissionItem) { return `colorSet-${record.staffInfo?.typeColor}`; }, []);

  if (!open) return null;
  return (
    <ModalDark
      containerClassName={'quick-commission'}
      headerStyles={headerStyles} footerStyles={footerStyles} bodyStyles={bodyStyles} containerStyles={containerStyles}
      titleAlign='left' loading={loading} width={'90vw'} FooterComponent={Footer} title='quick commission report' open={open} onClose={onClose}
      RightActionComponent={RightActionComponent}
    >
      <Form form={form} onFinish={handleUpdateItem}>
        <TableStyled
          rowKey={'billId'} locale={emptyLocaleTable} dataSource={dataSource} columns={columns}
          loading={state.loadingTable}
          // @ts-ignore
          rowClassName={rowClassName}
          pagination={{
            total: state.totalItems,
            pageSize: state.params.pageSize,
            current: state.params.page,
            showSizeChanger: false,
            onChange: handlePageChange,
          }}
          scroll={dataSource.length ? { x: '90vw', y: '60vh' } : undefined}
          summary={dataSource.length ? summary : undefined}
        />
        <Form.Item name={'ticketId'} noStyle />
      </Form>
      <AddNewQuickCommission activeStaff={activeStaff} ref={addNew} />
      {confirmContext}
      <BillPreviewLayout ref={billStaffCtr} billRef={staffPrintRef} doPrint={doStaffPrint} />
      <BillPreviewLayout owner ref={billOwnerCtr} billRef={ownerPrintRef} doPrint={doOwnerPrint} />
    </ModalDark>
  );
});
QuickCommission.displayName = 'QuickCommission';
export default QuickCommission;

const TableStyled = styled(Table)`
align-self: stretch;
width: 100%;
.dollar-input-selector {
  justify-content: flex-end;
  height: auto;
}
&.ant-table-wrapper .ant-table-body {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
&.ant-table-wrapper .ant-pagination.ant-table-pagination {
  margin: 8px 0;
}
&.ant-table-wrapper .ant-table.ant-table-empty .ant-table-thead>tr>th {
  position: relative;
}
&.ant-table-wrapper .ant-table-thead>tr>th, .ant-table-wrapper .ant-table-thead>tr>th{
  border-bottom: 1px solid #CCD4DC;
  border-right: 1px solid #FFF;
  background: #484F58;
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 4px 8px;
}
&.ant-table-wrapper .ant-table-tbody>tr>td.ant-table-cell {
  padding: 4px 16px;
  .ant-select-selection-item, .ant-input {
    font-size: 18px;
  }

  .ant-input.ant-input-disabled  {
    color: #1D2129;
  }
  &.number-row {
    text-align: right !important;
  }
}

&.ant-table-wrapper .ant-table-tbody>.ant-table-row.colorSet-default td{
  background: #FFFFFF;
}
&.ant-table-wrapper .ant-table-tbody>.ant-table-row.colorSet-0 td{
  background: #E5E3E6;
}
&.ant-table-wrapper .ant-table-tbody>.ant-table-row.colorSet-1 td{
  background: #c7d7f6;
}
&.ant-table-wrapper .ant-table-tbody>.ant-table-row.colorSet-2 td{
  background: #E4D3E7;
}
&.ant-table-wrapper .ant-table-tbody>.ant-table-row.colorSet-3 td{
  background: #FDFEDB;
}
&.ant-table-wrapper .ant-table-tbody>.ant-table-row.colorSet-4 td{
  background: #E6FAE2;
}
&.ant-table-wrapper .ant-table-tbody>.ant-table-row.colorSet-5 td{
  background: #F2CEE6;
}
&.ant-table-wrapper .ant-table-tbody>.ant-table-row.colorSet-6 td{
  background: #FFFFFF;
}

&.ant-table-wrapper .ant-table-summary .ant-table-cell {
  color: #1D2129;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  &.value {
    text-align: right;
  }
  &.action, &.total {
    z-index: 10;
    &::after {
      box-shadow: none;
    }
  }
  &.action {
    right: 0;
  }
}
`;
const TicketNumber = styled.div`
  background: #1D2129;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  padding: 0 8px;
  width: 36px;
  span {
    color: #fff;
  }
`;
const RightActionStyled = styled.div`
  display: flex;
  align-items: center;
  flex:  1;
  justify-content: flex-end;
  gap: 1rem;
`;
const Actions = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 12px;
button.btn {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #E8F3FF;
  &:active {
    opacity: 0.8;
  }

  
  &.btn-delete {
    background: #FFF5F3;
  }
  &.btn-cancel {
    background: #FF3737;
  }
  &.btn-accept {
    background: #148CF1;
  }
}
`;

const PaymentType = ({ type }: { type: number }) => {
  switch (type) {
    case QuickCommissionPaymentMethods.CREDIT_CARD:
      return <PaymentTypeContainer>
        <img src={credit_card_icon} alt="credit_card_icon" style={{ width: 24, height: 24 }} />
        <span>Credit card</span>
      </PaymentTypeContainer>;
    case QuickCommissionPaymentMethods.GIFT_CARD:
      return <PaymentTypeContainer>
        <img src={gift_card_icon} alt="gift_card_icon" style={{ width: 24, height: 24 }} />
        <span>Gift card</span>
      </PaymentTypeContainer>;
    case QuickCommissionPaymentMethods.CASH:
      return <PaymentTypeContainer>
        <img src={cash_icon} alt="cash_icon" style={{ width: 24, height: 24 }} />
        <span>Cash</span>
      </PaymentTypeContainer>;
    default:
      return null;
  }
};

const PaymentTypeContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 4px;
span {
  color: #1D2129;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
`;

const ReceiveValueForm = ({ value, render, onChange }: { onChange?: (value: any) => void, value?: any, render?: (value: any, onChange?: (value: any) => void) => any }) => render ? render(value, onChange) : value;
const PassValueForm = ({ name, render }: { name?: any, render?: (value: any, onChange?: (value: any) => void) => any }) => <Form.Item noStyle name={name}><ReceiveValueForm render={render} /></Form.Item>;

