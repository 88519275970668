import { Form } from 'antd';
import { AxiosResponse } from 'axios';
import ModalDark, { ModalDarkProps } from 'components/ModalDark';
import { first } from 'lodash';
import moment from 'moment';
import ModalStaff, { useModalStaffRef } from 'QuickBooking/ModalStaff';
import { CSSProperties, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import commActions from 'services/actions/comm';
import apis from 'services/apis';
import shopSelectors from 'services/selectors/shop';
import { IApiAddCommissionBody } from 'services/types/comm';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
import uuid from 'utils/uuid';
import ModalNumberPad, { useModalNumberPadRef } from 'widgets/ModalNumberPad';
import FormInputs from './FormInputs';
import TicketTableContent, { QuickCommissionPaymentMethods } from './TicketTableContent';

const containerStyles: CSSProperties = {
  minHeight: '600px',
  background: '#fff',
};
const headerStyles: CSSProperties = {
  position: 'sticky',
  top: '8px',
  zIndex: 99,
};
const bodyStyles: CSSProperties = {
  flex: 1,
};
const footerStyles: CSSProperties = {
  position: 'sticky',
  bottom: '8px',
  zIndex: 99,
};
interface IFormValues {
  staff: IStaffUI;
  tickets: IFormValueTicketItem[];
}

export interface IFormValueTicketItem {
  id: string;
  ticketNumber: number;
  paymentMethod: number;
  sale: number;
  tip: number;
  discount: number;
  supply: number;
  note: string;
  staffId: string;
}

type Props = {
  activeStaff: {
    staffId: string;
    staffName: string;
    position: string;
  } | null;
};
type Ref = {
  open: () => void;
};
export const useAddNewQuickCommissionRef = () => useRef<Ref>(null);
const AddNewQuickCommission = forwardRef<Ref, Props>(({ activeStaff }, ref) => {
  const dispatch = useAppDispatch();
  const staffs = shopSelectors.staff();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const chooseStaffRef = useModalStaffRef();
  const modalNumberPadRef = useModalNumberPadRef();
  const [formAddTicket] = Form.useForm();
  const onClose = () => {
    if (nextRef.current) {
      const date = moment().format('MM-DD-YYYY');
      dispatch(commActions.setParams({ startDate: date, endDate: date }));
    }
    setOpen(false);
  };
  const [loading, setLoading] = useState(false);
  const nextRef = useRef<boolean>(false);

  const Footer = () => {
    return <>
      <button onClick={onClose} type='button' className="btn"><span>Close</span></button>
      <button onClick={() => form.resetFields(['tickets'])} type='button' className="btn btn-submit outline"><span>Reset</span></button>
      <button onClick={() => {
        form.submit();
        nextRef.current = false;
      }} type='button' className="btn btn-submit"><span>save</span></button>
    </>;
  };

  const handleFinish = async (values: any) => {
    if (!activeStaff) return null;
    const data = values as IFormValues;
    const staffObject: Record<string, IFormValues['tickets']> = {};
    data.tickets.map(o => {
      if (!staffObject[o.staffId]) {
        staffObject[o.staffId] = [o];
        return;
      }
      staffObject[o.staffId].push(o);
    });
    const list: IApiAddCommissionBody[] = [];
    Object.entries(staffObject).map(([_staffId, tickets]) => {
      list.push({
        createDate: moment().format('MM-DD-YYYY'),
        items: tickets.map((o) => ({
          discount: o.discount,
          paymentType: o.paymentMethod,
          sales: o.sale,
          supply: o.supply,
          tip: o.tip,
          total: o.sale + o.tip - o.discount + o.supply,
        })),
        staffId: +_staffId,
        staffAddTicket: +activeStaff?.staffId,
      });
    });

    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: any }> = await apis.addMultipleQuickCommission(list);
      if (res.data.payload) {
        const date = moment().format('MM-DD-YYYY');
        if (!nextRef.current) {
          dispatch(commActions.setParams({ startDate: date, endDate: date }));
          onClose();
        } else {
          form.resetFields();
          const idx = staffs.findIndex(o => o.id.toString() === data.staff.id?.toString());
          if (idx === staffs?.length - 1) {
            form.setFieldValue('staff', first(staffs));
          } else {
            const staffList = staffs.slice(idx + 1);
            form.setFieldValue('staff', first(staffList));
          }
        }
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      form.resetFields();
      if (activeStaff?.position === 'Technician') {
        formAddTicket.setFieldValue('staffId', activeStaff?.staffId);
        modalNumberPadRef.current?.open((numberTicket: number) => {
          const list = Array.from(Array(+numberTicket).keys()).map(() => ({
            id: uuid(),
            ticketNumber: 1,
            paymentMethod: QuickCommissionPaymentMethods.CREDIT_CARD,
            sale: null,
            tip: null,
            discount: null,
            supply: null,
            staffId: activeStaff.staffId,
            note: '',
          }));
          form.setFieldValue('tickets', list);
          setOpen(true);
        });
      } else {
        chooseStaffRef.current?.openCallback((staff) => {
          modalNumberPadRef.current?.open((numberTicket: number) => {
            const list = Array.from(Array(+numberTicket).keys()).map(() => ({
              id: uuid(),
              ticketNumber: 1,
              paymentMethod: QuickCommissionPaymentMethods.CREDIT_CARD,
              sale: null,
              tip: null,
              discount: null,
              supply: null,
              staffId: staff.id,
              note: '',
            }));
            form.setFieldValue('tickets', list);
            setOpen(true);
          });
        });
      }
    },
  }));
  const RightActionComponent = useCallback(() => <RightActionStyled><FormInputs form={formAddTicket} activeStaff={activeStaff} masterForm={form} /></RightActionStyled>, [activeStaff]);

  const Wrapper: ModalDarkProps['Wrapper'] = useCallback(({ children }: { children?: any }) => <FormStyled form={form} layout='vertical' initialValues={{ tickets: [] }} onFinish={handleFinish}>{children}</FormStyled>, []);

  return (
    <ModalDark headerStyles={headerStyles} footerStyles={footerStyles} bodyStyles={bodyStyles} containerStyles={containerStyles} Wrapper={Wrapper} titleAlign='left' loading={loading} FooterComponent={Footer} title='add quick commission' width={'auto'} open={open} onClose={onClose} RightActionComponent={RightActionComponent}>
      <Container>
        <div style={{ alignSelf: 'stretch' }}>
          <Form.List name={'tickets'} rules={[{
            validator(rule, value) {
              if (!value.length) return Promise.reject('Required');
              return Promise.resolve();
            },
          }]}>
            {(tickets, { remove }) => {
              return (
                <TicketTableContentStyled>
                  <TicketTableContent tickets={tickets} remove={remove} />
                </TicketTableContentStyled>
              );
            }}
          </Form.List>
        </div>
      </Container>
      <ModalStaff title='CHOOSE STAFF' ref={chooseStaffRef} staffList={staffs} />
      <ModalNumberPad maxValue={100} enterText='ADD' title={'Add Number Tickets'} ref={modalNumberPadRef} />
    </ModalDark>
  );
});
const FormStyled = styled(Form)``;

AddNewQuickCommission.displayName = 'AddNewQuickCommission';
export default AddNewQuickCommission;
const TicketTableContentStyled = styled.div`
  align-self: stretch;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px; 
  align-self: stretch;
  background: #F8F9FE;
`;

const RightActionStyled = styled.div`
  display: flex;
  align-items: center;
  flex:  1;
  justify-content: flex-end;
  gap: 1rem;

  .ant-select.ant-select-single {
    height: auto;
  }
`;