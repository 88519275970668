import { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { DollarInputRef, dollarAmountNumberMask, parseInputValue, parseOutputValue, parseStrInputValue } from './helpers';
import { InputProps } from 'antd';
import { delay } from 'lodash';
import clsx from 'clsx';
const ContainerStyled = styled.label`
border: 1px solid #86909C;
min-width: 60px;
border-radius: 4px;
background: #fff;
height: 3rem;
padding: 0 16px;
display: flex;
position: relative;
z-index: 1;
align-items: center;
justify-content: flex-start;
overflow-y: unset;
&:hover {
  cursor: text;
}
&::-webkit-scrollbar {
  display: none;
}
-ms-overflow-style: none;
scrollbar-width: none;
  input:not(.text-placeholder) {
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    background: #fff;
    position: relative;
    z-index: 2;

     &::placeholder {
      color: #64748B;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: #64748B;
    }
  }

  span {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .dollar-input-text {
    color: #232F3E;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
  span.text-measure {
    visibility: hidden;
    white-space: pre;
    position: absolute;
  }
  span.text-placeholder {
    white-space: pre;
    position: absolute;
    color: #64748B !important;
    display: flex;
    align-items: center;
  }
  input.text-placeholder {
    white-space: pre;
    position: absolute;
    color: #64748B !important;
    display: flex;
    align-items: center;
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    pointer-events: none;
    background: transparent !important;
  }

&.dollar-input-selector-focus {
  border: 1px solid #ffb0b3;
}
`;
type Props = {
  value?: number;
  onChange?: (value: number) => void;
  maxValue?: number;
  inputMode?: InputProps['inputMode'];
  onFocus?: InputProps['onFocus'];
  disabled?: boolean;
  className?: string;
};

const DollarInput = forwardRef<DollarInputRef, Props>(({ className, disabled, value: valueProp, maxValue, onChange = () => undefined, inputMode, onFocus = () => undefined }, ref) => {
  const [text, setText] = useState('');
  const selectorRef = useRef<HTMLLabelElement>(null);
  const inputSelectorRef = useRef<HTMLDivElement>(null);
  const textMeasureRef = useRef<HTMLSpanElement>(null);
  const textPlaceholderRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const value = text || parseInputValue(valueProp);
  const setValue: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const numericValue = parseOutputValue(e.target.value);
    if (maxValue && numericValue > maxValue) return e.preventDefault();
    setText(e.target.value);
    onChange(numericValue);
  };

  const updateInputWidth = () => {
    if (textMeasureRef.current && inputRef.current) {
      const width = textMeasureRef.current.offsetWidth;
      inputRef.current.style.width = `${width}px`;
    }
    if (textPlaceholderRef.current && inputSelectorRef.current) {
      const width = textPlaceholderRef.current.offsetWidth;
      inputSelectorRef.current.style.width = `${width}px`;
    }
  };
  useLayoutEffect(() => {
    delay(() => updateInputWidth(), 100);
  }, []);


  useEffect(() => {
    updateInputWidth();
  }, [value]);



  useImperativeHandle(ref, () => ({
    focus: (_value) => {
      const _text = _value || parseInputValue(0);
      const numericValue = parseOutputValue(_text);
      inputRef.current?.focus();
      delay(() => inputRef.current?.setSelectionRange(_text.length, _text.length), 20);
      if (maxValue && numericValue > maxValue) return;
      setText(_text);
    },
    init: (_value, unFocus = false) => {
      const _text = parseStrInputValue(_value) || parseInputValue(0);
      const numericValue = parseOutputValue(_text);
      if (maxValue && numericValue > maxValue) return;
      setText(_text);
      onChange(numericValue);
      // @ts-ignore
      if (!unFocus) inputRef.current?.focus({ cursor: 'end' });
    },
    clear: () => {
      setText(parseInputValue(0));
      onChange(0);
    },
    getText: () => text === '$0' ? '$' : text,
  }));
  const _onFocus: InputProps['onFocus'] = (e) => {
    selectorRef.current?.classList.add('dollar-input-selector-focus');
    onFocus(e);
  };
  return (
    <ContainerStyled ref={selectorRef} className={clsx('dollar-input-selector', className)} style={disabled ? { pointerEvents: 'none' } : {}}>
      <div ref={inputSelectorRef} style={{ position: 'relative', display: 'inline-flex', alignSelf: 'stretch', alignItems: 'center' }}>
        <MaskedInput
          mask={dollarAmountNumberMask}
          value={value}
          inputMode={inputMode}
          onChange={setValue}
          render={(textMaskRef: any, props: any) => {
            return (
              <input
                ref={ref => {
                  textMaskRef(ref);
                  // @ts-ignore
                  inputRef.current = ref;
                }}
                {...props}
                value={value}
                placeholder="$0"
                onFocus={_onFocus}
                onBlur={() => selectorRef.current?.classList.remove('dollar-input-selector-focus')}
                className='dollar-input-text'
              />
            );
          }}
        />
        <span className='dollar-input-text text-measure' ref={textMeasureRef} >{value || '$0'}</span>
        <input disabled className='dollar-input-text  text-placeholder' value={formatCurrency(value)} />
        <span ref={textPlaceholderRef} className='dollar-input-text text-placeholder text-measure' >{formatCurrency(value)}</span>
      </div>
    </ContainerStyled >
  );
});

DollarInput.displayName = 'DollarInput';
export default DollarInput;

export const DollarInputLG = styled(DollarInput)`
  justify-content: center;
  &.dollar-input-selector {
    border-radius: 4px;
    border: 1px solid #CCD4DC;
    background: #FFF;
    padding: 0;
  }
  .dollar-input-text {
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  }
`;
