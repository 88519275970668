import { signal } from '@preact/signals-react';
import moment from 'moment';
import { ICustomerUI } from 'types/customer';
import { IDatePickerValue } from './TimeTab/DatePicker';
import { ADD_APPOINTMENT_TABS } from './helper';
import { APPOINTMENT_GROUP_VALUE } from './ReviewTab/RadioGroup';
import { AppointmentStatus } from 'Turn/services/constants';
import { IServiceItemData } from 'types/appointment';
import { dateParamSignal } from 'CalendarFilter/DatePicker';

const openSignal = signal<boolean>(false);

const tabSignal = signal<string>(ADD_APPOINTMENT_TABS.CUSTOMER);

const timeSignal = signal<IDatePickerValue>(null);

const activeDateSignal = signal<IDatePickerValue>(moment());

const staffIdSignal = signal<string | null>(null);

const customerSignal = signal<ICustomerUI | null>(null);

const servicesSignal = signal<{ serviceId: string, staffId: string, name: string, duration: number, price: number }[]>([]);

const detailIdSignal = signal<string | null>(null);

const detailStatusSignal = signal<AppointmentStatus | null>(null);

const noteSignal = signal<string | null>(null);

const groupTabSignal = signal<APPOINTMENT_GROUP_VALUE>(APPOINTMENT_GROUP_VALUE.INDIVIDUAL_APT);

const groupValueSignal = signal<string | null>(null);

const isViewOnly = signal<boolean>(false);

const isQuickBooking = signal<boolean>(false);

const requestStaff = signal<boolean>(false);

export const totalDurationSignal = signal<number>(0);
export const totalDurationSignalDefault = signal<number>(0);

const moveToTab = (tab: 'customer' | 'staff' | 'time' | 'service' | 'review') => {
  switch (tab) {
    case 'customer':
      tabSignal.value = ADD_APPOINTMENT_TABS.CUSTOMER;
      break;
    case 'staff':
      tabSignal.value = ADD_APPOINTMENT_TABS.STAFF;
      break;
    case 'time':
      tabSignal.value = ADD_APPOINTMENT_TABS.TIME;
      break;
    case 'service':
      tabSignal.value = ADD_APPOINTMENT_TABS.SERVICE;
      break;
    case 'review':
      tabSignal.value = ADD_APPOINTMENT_TABS.REVIEW;
      break;

    default:
      break;
  }
};

const setActiveKey = (val: string) => {
  aptSignal.tabSignal.value = val;
};

const navToReview = () => {
  setActiveKey(ADD_APPOINTMENT_TABS.REVIEW);
};

const next = () => {
  const { tabSignal, customerSignal, staffIdSignal, timeSignal, servicesSignal } = aptSignal;
  switch (tabSignal.value) {
    case ADD_APPOINTMENT_TABS.CUSTOMER: {
      if (staffIdSignal.value && timeSignal.value && servicesSignal.value.length)
        return !!customerSignal.value?.id && setActiveKey(ADD_APPOINTMENT_TABS.REVIEW);

      if (staffIdSignal.value && timeSignal.value)
        return !!customerSignal.value?.id && setActiveKey(ADD_APPOINTMENT_TABS.SERVICE);

      if (staffIdSignal.value)
        return !!customerSignal.value?.id && setActiveKey(ADD_APPOINTMENT_TABS.TIME);

      return !!customerSignal.value?.id && setActiveKey(ADD_APPOINTMENT_TABS.STAFF);
    }
    case ADD_APPOINTMENT_TABS.STAFF: {
      if (timeSignal.value && servicesSignal.value.length) {
        return !!staffIdSignal.value && setActiveKey(ADD_APPOINTMENT_TABS.REVIEW);
      }

      return !!staffIdSignal.value && setActiveKey(ADD_APPOINTMENT_TABS.TIME);
    }
    case ADD_APPOINTMENT_TABS.TIME: {
      if (servicesSignal.value.length) {
        return !!timeSignal.value && setActiveKey(ADD_APPOINTMENT_TABS.REVIEW);
      }

      return !!timeSignal.value && setActiveKey(ADD_APPOINTMENT_TABS.SERVICE);
    }
    case ADD_APPOINTMENT_TABS.SERVICE:
      return !!servicesSignal.value?.length && setActiveKey(ADD_APPOINTMENT_TABS.REVIEW);
    default:
      break;
  }
};

const getDisabled = () => {
  const { tabSignal, customerSignal, staffIdSignal, timeSignal, servicesSignal } = aptSignal;
  switch (tabSignal.value) {
    case ADD_APPOINTMENT_TABS.CUSTOMER:
      return !customerSignal.value?.id;
    case ADD_APPOINTMENT_TABS.STAFF:
      return !staffIdSignal.value;
    case ADD_APPOINTMENT_TABS.TIME:
      return !timeSignal.value;
    case ADD_APPOINTMENT_TABS.SERVICE:
      return !servicesSignal.value?.length;
    case ADD_APPOINTMENT_TABS.REVIEW:
      return !aptSignal.getValid();
    default:
      break;
  }
};

const getValid = () => {
  const { customerSignal, staffIdSignal, timeSignal, servicesSignal } = aptSignal;
  return !!customerSignal.value?.id && !!staffIdSignal.value && !!timeSignal.value && !!servicesSignal.value?.length;
};

const getValues = () => {
  const time = timeSignal.value;
  const newDateValue = aptSignal.activeDateSignal.value?.clone().set({
    hour: time?.get('hour'),
    minute: time?.get('minute'),
    second: time?.get('second'),
  });

  return ({
    time: newDateValue,
    staffId: staffIdSignal.value,
    customer: customerSignal.value,
    services: servicesSignal.value,
    totalDuration: totalDurationSignal.value || totalDurationSignalDefault.value,
    note: noteSignal.value,
    totalPeople: +(groupValueSignal.value || '') || 1,
    requestStaff: requestStaff.value,
  });
};

const aptSignal = {
  open: (date?: string, staffId?: string) => {
    openSignal.value = true;
    timeSignal.value = date ? moment(date) : null;
    activeDateSignal.value = date ? moment(date) : (dateParamSignal.value || moment());
    staffIdSignal.value = staffId || null;
    isViewOnly.value = false;
    requestStaff.value = false;
    isQuickBooking.value = false;
  },
  openDetail: (data: {
    id: string;
    customer: ICustomerUI;
    staffId: string;
    time: string;
    totalDuration: number;
    services: IServiceItemData[];
    quickBook?: boolean;
    note?: string;
    isViewOnly?: boolean;
    requestStaff?: boolean;
    status?: AppointmentStatus;
  }) => {
    openSignal.value = true;
    detailIdSignal.value = data.id;
    detailStatusSignal.value = data.status || null;
    timeSignal.value = data.time ? moment(data.time) : null;
    activeDateSignal.value = data.time ? moment(data.time) : moment();
    staffIdSignal.value = data.staffId || null;
    servicesSignal.value = data.services.map(o => ({
      serviceId: o.serviceId,
      staffId: data.staffId || '',
      name: o.serviceName,
      duration: o.duration || 0,
      price: o.price || 0,
    }));
    customerSignal.value = data.customer;
    tabSignal.value = ADD_APPOINTMENT_TABS.REVIEW;
    noteSignal.value = data.note || '';
    groupTabSignal.value = APPOINTMENT_GROUP_VALUE.INDIVIDUAL_APT;
    isViewOnly.value = data.isViewOnly || false;
    isQuickBooking.value = data.quickBook || false;
    requestStaff.value = data.requestStaff || false;
    totalDurationSignalDefault.value = data.totalDuration || 0;
  },
  close: () => {
    openSignal.value = false;
    timeSignal.value = null;
    staffIdSignal.value = null;
    tabSignal.value = ADD_APPOINTMENT_TABS.CUSTOMER;
    customerSignal.value = null;
    servicesSignal.value = [];
    detailIdSignal.value = null;
    detailStatusSignal.value = null;
    totalDurationSignal.value = 0;
    noteSignal.value = null;
    groupValueSignal.value = null;
    groupTabSignal.value = APPOINTMENT_GROUP_VALUE.INDIVIDUAL_APT;
    isViewOnly.value = false;
    requestStaff.value = false;
  },
  next,
  moveToTab,
  openSignal,
  timeSignal,
  staffIdSignal,
  tabSignal,
  customerSignal,
  servicesSignal,
  getValid,
  getDisabled,
  setActiveKey,
  getValues,
  detailIdSignal,
  detailStatusSignal,
  noteSignal,
  groupTabSignal,
  activeDateSignal,
  groupValueSignal,
  isViewOnly,
  isQuickBooking,
  requestStaff,
  navToReview,
  totalDurationSignal,
  totalDurationSignalDefault,
};

export default aptSignal;
