import CalendarFilter from 'CalendarFilter';
import LoadingCalendar from 'components/Loading';
import Text from 'components/Text';
import { useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import CalendarViewBody from './Body';
import CalendarViewHeader from './Header';
import CalendarStyled, { calendarDOM, isModeFull } from './styles';
import useCalendarFilter from './useCalendarFilter';
import useCalendarMapping from './useCalendarMapping';
import { signal } from '@preact/signals-react';
import calcTo from './helper';
import moment from 'moment';

export const calendarScrollTo = signal<(to: number, left?: number) => void>(() => undefined);

const Calendar = () => {
  const calendarRef = useRef<HTMLTableElement>(null);
  const schema = useCalendarFilter();
  const body = useCalendarMapping(schema);

  useLayoutEffect(() => {
    calendarScrollTo.value = (top: number, left?: number) => {
      setTimeout(() => calendarRef.current?.scrollTo({ top: top, left, behavior: 'smooth' }), 0);
    };
  }, [calendarRef]);

  useEffect(() => {
    const pixel = calcTo(moment().format('MM-DD-YYYY HH:mm:ss'));
    if (pixel)
      setTimeout(() => calendarRef.current?.scrollTo({ top: pixel - 8, behavior: 'smooth' }), 0);
  }, []);

  return (
    <>
      <CalendarFilter />
      <Text className='spacing-div' mb={isModeFull.value ? 0 : 1.5} />
      <CalendarTableViewStyled id='calendar' ref={calendarDOM} className='zoom-level-1'>
        <CalendarTableOverflowStyled ref={calendarRef}>
          <div className="calendar">
            <CalendarStyled.Table typeView={schema.typeView} >
              <CalendarViewHeader headers={schema.headers} renderHeader={schema.renderHeader} />
              <CalendarViewBody rows={body.rows} rowsSpanInfo={body.rowsSpanInfo} distanceTimeline={schema.distanceTimeline} />
            </CalendarStyled.Table>
          </div>
        </CalendarTableOverflowStyled>
        <LoadingCalendar />
      </CalendarTableViewStyled>
    </>
  );
};

export default Calendar;

const CalendarTableViewStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;

  &.zoom-level-1 {
    .calendar table thead th {
      height: 35px;
      min-width: 10rem;
      max-width: 10rem;
      .ant-avatar.ant-avatar-lg {
        width: 30px;
        height: 30px;
      }
      .StaffBox .subtext {
        font-size: var(--zoom-level-1-subtext) !important;
      }
    }
    .calendar table tbody td {
      height: 60px;
      min-width: 10rem;
      max-width: 10rem;

      .text-time {
        font-size: var(--zoom-level-1-text);
      }

      .AppointmentStyled, .MultiAppointmentStyled, .BlockHourItemStyled {
        .appointment-service-sm, .appointment-time-sm, .appointment-name-sm {
          font-size: var(--zoom-level-1-appointment-sm);
          line-height: calc(var(--zoom-level-1-appointment-sm) + 4px);
        }
        .appointment-icon-sm {
          width: 20px;
          padding: 0;
        }
        .appointment-icon {
          width: 20px;
          padding: 0;
          display: flex;
        }

        .appointment-time, .appointment-name, .appointment-service {
          font-size: var(--zoom-level-1-text);
        }
        .block-hour-time {
          font-size: var(--zoom-level-1-text);
        }
        .block-hour-title {
          font-size: calc(var(--zoom-level-1-text) - 2px);
        }
        .block-hour-note {
          font-size: calc(var(--zoom-level-1-text) - 2px);
        }
      }
    }
  }


  &.zoom-level-2 {
    .calendar table thead th {
      height: 30px;
      min-width: 7rem;
      max-width: 7rem;
      .ant-avatar.ant-avatar-lg {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-avatar-string {
          position: relative;
          transform: unset !important;
          left: unset;
        }
      }
      .StaffBox .subtext {
        font-size: var(--zoom-level-2-subtext) !important;
        margin-top: 0 !important;
      }
    }
    .calendar table .fixed-time-td {
        max-width: 6.5rem !important;
        min-width: 6.5rem !important;
        width: 6.5rem !important;
    }
    .calendar table .fixed-time-anyone {
        left: 6.5rem;
    }
    .calendar table tbody td {
      height: 50px;
      min-width: 7rem;
      max-width: 7rem;

      .text-time {
        font-size: var(--zoom-level-2-text);
      }

      .AppointmentStyled, .MultiAppointmentStyled, .BlockHourItemStyled {
        .appointment-service-sm, .appointment-time-sm, .appointment-name-sm {
          font-size: var(--zoom-level-2-appointment-sm);
          line-height: calc(var(--zoom-level-2-appointment-sm) + 4px);
        }
        .appointment-icon-sm {
          width: 12px;
          height: 12px;
          display: flex;
          padding: 0;
          svg {
            height: 100%;
            width: 100%;
          }
        }
        .appointment-icon {
          width: 16px;
          height: 16px;
          display: flex;
          padding: 0;
          svg {
            height: 100%;
            width: 100%;
          }
        }
        .appointment-box.appointment-box-sm {
          justify-content: unset;
        }
        .appointment-time, .appointment-name {
          font-size: var(--zoom-level-2-text);
        }
        .appointment-alert-icon svg{
          transform: scale(1);
        }
        .appointment-service {
          font-size: calc(var(--zoom-level-2-text) - 2px);
        }
        .block-hour-time {
          font-size: calc(var(--zoom-level-2-text) - 2px);
        }
        .appointment-block-icon {
          width: 12px;
          height: 12px;
          display: flex;
          padding: 0;
          svg {
            height: 100%;
            width: 100%;
          }
        }
        .block-hour-title {
          font-size: calc(var(--zoom-level-2-text) - 2px);
        }
        .block-hour-note {
          font-size: calc(var(--zoom-level-2-text) - 2px);
        }
      }
    }
  }

  &.zoom-level-0 {
    .calendar table thead th {
      height: 40px;
      min-width: 12rem;
      max-width: 12rem;
      .ant-avatar.ant-avatar-lg {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-avatar-string {
          position: relative;
          transform: unset !important;
          left: unset;
        }
      }
      .StaffBox .subtext {
        font-size: var(--zoom-level-0-subtext) !important;
      }
    }
    .calendar table .fixed-time-td {
        max-width: 7.5rem !important;
        min-width: 7.5rem !important;
        width: 7.5rem !important;
    }
    .calendar table .fixed-time-anyone {
        left: 7.5rem;
    }
    .calendar table tbody td {
      height: 60px;
      min-width: 12rem;
      max-width: 12rem;

      .text-time {
        font-size: var(--zoom-level-0-text);
      }

      .AppointmentStyled, .MultiAppointmentStyled, .BlockHourItemStyled {
       
        .appointment-icon-sm {
          width: 16px;
          height: 16px;
          display: flex;
          padding: 0;
          svg {
            height: 100%;
            width: 100%;
          }
        }
        .appointment-icon {
          width: 16px;
          height: 16px;
          display: flex;
          padding: 0;
          svg {
            height: 100%;
            width: 100%;
          }
        }
        .appointment-box.appointment-box-sm {
          justify-content: unset;
        }
        .appointment-time, .appointment-name, .appointment-service {
          font-size: var(--zoom-level-0-text);
        }
        .appointment-alert-icon svg{
          transform: scale(1.3);
        }
        .block-hour-time {
          font-size: var(--zoom-level-0-text);
        }
        .appointment-block-icon {
          width: 16px;
          height: 16px;
          display: flex;
          padding: 0;
          svg {
            height: 100%;
            width: 100%;
          }
        }
        .block-hour-title {
          font-size: calc(var(--zoom-level-0-text) - 2px);
        }
        .block-hour-note {
          font-size: calc(var(--zoom-level-0-text) - 2px);
        }
      }
    }
  }
`;

const CalendarTableOverflowStyled = styled.div`
  position: relative;
  max-height: 100%;
  flex: 1;
  height:100%;
  z-index: 1;
  max-width: 100%;
  overflow: scroll;
  overscroll-behavior: none;

  .calendar {
    position: absolute;
    inset: 0;
  }
`;