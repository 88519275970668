import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import QuickCommissionReceiptOwner from './QuickCommisison/receipt.owner';
import QuickCommissionReceiptStaff from './QuickCommisison/receipt.staff';

function ReceiptReviewWrapperLayout({ children, className }: any) {
  // return <Outlet/>;
  return (
    <Container className={className}>
      <BoxReceiptContainer className='box-receipt-container'>
        {children ? children : <Outlet />}
      </BoxReceiptContainer>
    </Container>
  );
}

export default ReceiptReviewWrapperLayout;

const Container = styled.div`
background: #F6F8FC;
min-height: 100vh;
padding: 32px 0;
display: flex;
align-items: center;
justify-content: center;
`;

const BoxReceiptContainer = styled.div`
display: flex;
width: 324px;
flex-direction: column;
align-items: center;
gap: 24px;
border-radius: 5px;
background: #FFF;
`;

type IBillPreviewLayoutProps = {
  owner?: boolean;
  billRef?: React.RefObject<HTMLDivElement>;
  doPrint?: () => void;
};
type Ref = {
  open: () => void;
  close: () => void;
};
export const useBillPreviewLayout = () => useRef<Ref>(null);
export const BillPreviewLayout = forwardRef<Ref, IBillPreviewLayoutProps>(({ owner, billRef, doPrint = () => undefined }, ref) => {
  const [open, setOpen] = React.useState(false);
  const onClose = () => setOpen(false);
  const handlePrint = () => {
    doPrint();
    onClose();
  };
  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
  }));
  return (
    <WrapperPrinted style={open ? { display: 'flex' } : { display: 'none' }}>
      <ReceiptReviewWrapperLayout className='receipt-review-wrapper'>
        {owner ? <QuickCommissionReceiptOwner billRef={billRef} /> : <QuickCommissionReceiptStaff billRef={billRef} />}
      </ReceiptReviewWrapperLayout>
      <div className="actions-container">
        <div className='actions'>
          <button onClick={handlePrint} type='button' className="btn btn-print"><span>Print</span></button>
          <button onClick={onClose} type='button' className="btn"><span>Close</span></button>
        </div>
      </div>
    </WrapperPrinted>
  );
});
const WrapperPrinted = styled.div`
position: fixed;
inset: 0;
z-index: 99999;
background: rgba(0, 0, 0, 0.2);
display: flex;
flex-direction: row;
overflow: auto;
gap: 8px;
justify-content: center;
.receipt-review-wrapper {
  background: unset;
  padding-bottom: 100px;
  min-height: 125vh;
  align-items: flex-start;
  .EmployeesOwner table {
    zoom: 1;
  }
}
.box-receipt-container {
  width: 400px;
}
.actions-container  {
  position: relative;
}
.actions { 
  display: flex;
  flex-direction: column;
  align-self: self-end;
  position: fixed;
  top: 32px;
  gap: 16px;
  justify-content: center;
  align-items: center;
  button.btn {
    width: 200px;
    display: inline-flex;
    min-height: 48px;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #86909C;
    background: #F1F1F1;
    span {
      color: #2C333A;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase; 
    }

    &.btn-print {
      background: #148CF1;
      border: 1px solid #148CF1;
      span {
        color: #FFF;
      }
    }
  }
}
`;