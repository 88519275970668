import { sumBy } from 'lodash';
import React from 'react';
import { IReportOwnerCommissionResData } from 'services/types/comm';
import styled from 'styled-components';
import { formatCurrencyShort } from 'utils/formatCurrency';
type Props = {
  data: IReportOwnerCommissionResData | null;
};

const EmployeesOwner = ({ data }: Props) => {
  return (
    <Container className='EmployeesOwner'>
      <div className="section-title">Employee Report</div>
      <table>
        <thead>
          <tr className='bold-row'>
            <th className='align-left'>SALES</th>
            <th>SUPPLY</th>
            <th>DISC</th>
            <th>COMM</th>
            <th className='align-right'>TIP</th>
          </tr>
        </thead>
        <tbody>
          {data?.staffSalary?.map(staff => (
            <React.Fragment key={staff.staffId}>
              <tr className='staff-name-row'><td className='staff-name align-left' colSpan={5}>{staff.nameStaff}</td></tr>
              <tr className='detail-row'>
                <td className='align-left commissionSales'>{formatCurrencyShort(staff.commissionSales)}</td>
                <td className='supply'>{formatCurrencyShort(staff.supply)}</td>
                <td className='discount'>({formatCurrencyShort(staff.discount)})</td>
                <td className='commission'>{formatCurrencyShort(staff.commission)}</td>
                <td className='align-right tip'>{formatCurrencyShort(staff.tip)}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
        <tfoot>
          <tr className='divide-row'><td colSpan={5}></td></tr>
          <tr className="bold-row">
            <th className='align-left commissionSales'>{formatCurrencyShort(sumBy(data?.staffSalary, o => o.commissionSales))}</th>
            <th className='supply'>{formatCurrencyShort(sumBy(data?.staffSalary, o => o.supply))}</th>
            <th className='discount'>({formatCurrencyShort(sumBy(data?.staffSalary, o => o.discount))})</th>
            <th className='commission'>{formatCurrencyShort(sumBy(data?.staffSalary, o => o.commission))}</th>
            <th className='align-right tip'>{formatCurrencyShort(sumBy(data?.staffSalary, o => o.tip))}</th>
          </tr>
        </tfoot>
      </table>
    </Container>
  );
};

export default EmployeesOwner;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  // padding-bottom: 16px;
  table {
    align-self: stretch;
    zoom: 0.85;
    border-collapse: collapse;
    th, td {
      overflow: hidden;
      color: black;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }
    .align-left {
      text-align: left;
    }
    .align-right {
      text-align: right;
    }
    .align-center {
      text-align: center;
    }

    thead th, tfoot th {
      color: black;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    tr.staff-name-row td {
      overflow: hidden;
      color: black;
      text-align: left;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
    tr.detail-row td {
      color: black;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
      border-bottom: 1px solid black;
    }

    tr.divide-row td {
      padding-top: 8px;
      border-bottom: 1px solid black;
    }

    tr.bold-row {
      td, th {
        background: black;
        color: white;
        font-weight: 600;
      }
    }
  }
`;