import { DatePicker, TimeRangePickerProps } from 'antd';
import IconCalendar from 'assets/Icons/iconDate';
import dayjs, { Dayjs } from 'dayjs';
import { first, last } from 'lodash';
import React, { useMemo } from 'react';
import commActions from 'services/actions/comm';
import commSelectors from 'services/selectors/comm';
import { useAppDispatch } from 'store/hooks';
import { DATE_FORMAT } from 'utils/format';
const time = (date: string) => dayjs(date, DATE_FORMAT);

const rangePresets: TimeRangePickerProps['presets'] = [
  { label: 'Today', value: [dayjs(), dayjs().endOf('day')] },
  { label: 'Yesterday', value: [dayjs().subtract(1, 'd'), dayjs().subtract(1, 'd').endOf('day')] },
  {
    label: 'This week', value: () => {
      const currentDate = dayjs();
      return [currentDate.startOf('week'), currentDate.endOf('week')];
    }
  },
  {
    label: 'Last Week', value: () => {
      const currentDate = dayjs().subtract(1, 'week');
      return [currentDate.startOf('week'), currentDate.endOf('week')];
    }
  },
  {
    label: 'This month', value: () => {
      const currentDate = dayjs();
      return [currentDate.startOf('month'), currentDate.endOf('month')];
    }
  },
];

type RangeValue = [Dayjs, Dayjs];
type Props = {
  activeStaff: { staffId: string, position: string, staffName: string; } | null
};
const RangePicker = ({ }: Props) => {
  const dispatch = useAppDispatch();
  const params = commSelectors.params();
  const value = useMemo(() => [params.startDate ? time(params.startDate) : null, params?.endDate ? time(params?.endDate) : null].filter(Boolean) as RangeValue, [params]);
  const onChange = (range: any) => {
    const start_time = (first(range) as Dayjs)?.format(DATE_FORMAT);
    const end_time = (last(range) as Dayjs)?.format(DATE_FORMAT);
    dispatch(commActions.setParams({ startDate: start_time, endDate: end_time, page: 1, pageSize: 10 }));
  };
  return (
    <DatePicker.RangePicker
      value={value}
      format={'MMM DD, YYYY'}
      onChange={onChange}
      suffixIcon={<IconCalendar />}
      inputReadOnly
      allowClear={false}

      presets={rangePresets}
    />
  );
};

export default RangePicker;
